import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"

import PageSection from "../component-factories/page-section"

import {
  useMarketContext,
  hreflangTags,
} from "lib-vinomofo/contexts/market-context"

import PageLayout from "../components/page-layout"

const Homepage = ({ data }) => {
  const market = useMarketContext()

  const { siteMetadata } = data.site
  const { ...page } = data.sanityPage

  const pageTitle = `${page.title} | ${siteMetadata.title} ${market?.name}`
  const pageDescription = page.description || siteMetadata.description
  const canonicalUrl = `${market.canonical_url}/`

  return (
    <PageLayout>
      <Helmet>
        <title>{pageTitle}</title>

        {hreflangTags.map(tag => {
          return (
            <link
              key={tag.lang}
              rel="alternate"
              href={`${tag.href}/`}
              hrefLang={tag.lang}
            />
          )
        })}

        <link rel="canonical" href={canonicalUrl} />
        <meta name="description" content={pageDescription} />
      </Helmet>

      {page.sections.map((section, index) =>
        PageSection.create(section, index)
      )}
    </PageLayout>
  )
}

export default Homepage

export const query = graphql`
  query($pageId: String) {
    site {
      siteMetadata {
        title
        description
      }
    }
    sanityPage(id: { eq: $pageId }) {
      title
      description
      sections {
        ...PageSectionAttributes
      }
    }
  }
`
